import {
  Box,
  BoxProps,
  Heading,
  Link,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import NextLink from 'next/link';

import { IContactLink } from '../../../utils/constants';

interface ISecondaryLinksSectionProps extends BoxProps {
  header: string;
  links: IContactLink[] | IContactLink;
}

export const SecondaryLinksSection = ({
  header,
  links,
  ...props
}: ISecondaryLinksSectionProps) => {
  if (!links) {
    return null;
  }

  const linksArray = Array.isArray(links) ? links : [links];

  return (
    <Box minW={24} {...props}>
      <Heading
        variant="footer"
        fontWeight={{ base: 700, md: 400 }}
        fontSize="xs"
        textTransform="uppercase"
      >
        {header}
      </Heading>

      <UnorderedList
        listStyleType="none"
        ml={0}
        mt={6}
        fontSize="sm"
        lineHeight={5}
      >
        {linksArray.map((link) => (
          <ListItem key={link.label}>
            {/* TODO: add ticket to migrate it later  */}
            <NextLink legacyBehavior passHref href={link.href ?? ''}>
              <Link ref={link.refFunc} target={link.target}>
                {link.label}
              </Link>
            </NextLink>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};
