import { Fragment, useCallback, useState } from 'react';

import {
  Divider,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from '@chakra-ui/react';
import NextLink from 'next/link';

import { headerLinks, homepageLink } from '../../../utils/constants';
import { Hamburger } from '../icons/hamburger';
import RepublicCapitalLogo from '../images/republic-capital-logo.svg';
import { LinkButton } from './link-button';

export const HamburgerMenu = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <IconButton
        icon={<Hamburger fontSize="28px" />}
        onClick={handleOpen}
        ml={6}
        aria-label="Menu"
        variant="ghost"
      />
      <Modal isOpen={open} onClose={handleClose} size="full">
        <ModalContent bgColor="white" backdropFilter="blur(5px)">
          <ModalHeader>
            {/* TODO: add ticket to migrate it later  */}
            <NextLink legacyBehavior href={homepageLink} passHref>
              <Link onClick={handleClose}>
                <Image
                  w={{ base: '48px', md: '72px' }}
                  h={{ base: '48px', md: '72px' }}
                  src={RepublicCapitalLogo}
                  cursor="pointer"
                />
              </Link>
            </NextLink>
          </ModalHeader>
          <ModalCloseButton size="lg" top={5} right={6} />
          <ModalBody
            bgColor="transparent"
            display="flex"
            p={0}
            flexDirection="column"
          >
            <Divider color="white" />
            {Object.values(headerLinks).map((link) => (
              <Fragment key={link.label}>
                <LinkButton
                  ref={link.refFunc}
                  href={link.href ?? ''}
                  onClick={handleClose}
                  label={link.label}
                />
                <Divider color="white" />
              </Fragment>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
