import { Link, ListItem, ListProps, UnorderedList } from '@chakra-ui/react';

import { IContactLink } from '../../../utils/constants';

interface IPrimaryLinksSectionProps extends ListProps {
  links: IContactLink[];
}

export const PrimaryLinksSection = ({
  links,
  ...props
}: IPrimaryLinksSectionProps) => (
  <UnorderedList
    listStyleType="none"
    w={60}
    textDecor="underline"
    fontSize="3xl"
    lineHeight="46px"
    display="flex"
    flexDirection={{ base: 'row', md: 'column' }}
    alignItems={{ base: 'flex-start', md: 'flex-start' }}
    ml={0}
    {...props}
  >
    {links.map((link) => (
      <ListItem key={link.label} minW={{ base: 36, md: 'unset' }}>
        <Link href={link.href} p={0}>
          {link.label}
        </Link>
      </ListItem>
    ))}
  </UnorderedList>
);
