import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';

const baseStyleCloseButton: SystemStyleObject = {
  position: 'absolute',
  top: 2,
  insetEnd: 3,
  _focus: {
    boxShadow: 'none',
  },
};

const dialogContainer: SystemStyleObject = {
  // This should resolve Chakra UI Modal iOS issue: https://github.com/chakra-ui/chakra-ui/issues/4680
  '@supports(height: -webkit-fill-available)': {},
  height: '100%',
};

const baseStyle: PartsStyleFunction<typeof parts> = () => ({
  closeButton: baseStyleCloseButton,
  dialogContainer,
});

export const Modal = {
  baseStyle,
};
