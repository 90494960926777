import React, { useEffect, useRef } from 'react';

import {
  Box,
  Button,
  Container,
  Flex,
  Link,
  useBreakpointValue,
} from '@chakra-ui/react';
import NextLink from 'next/link';

import { headerLinks, homepageLink } from '../../utils/constants';
import { Subscribe } from '../layout/subscribe';
import { HamburgerMenu } from './hamburger-menu/hamburger-menu';
import { RepublicCapitalLogo } from './icons/republic-capital.logo';

enum E_BREAKPOINT {
  xs,
  sm,
  md,
  lg,
  xl,
}

interface IHeaderProps {
  variant?: 'base' | 'homepage';
}

export const Header = ({ variant }: IHeaderProps) => {
  const isHomepage = variant === 'homepage';

  const breakpoint =
    useBreakpointValue({
      base: E_BREAKPOINT.xs,
      sm: E_BREAKPOINT.sm,
      md: E_BREAKPOINT.md,
      lg: E_BREAKPOINT.lg,
      xl: E_BREAKPOINT.xl,
    }) || 0;

  const [isStuck, setIsStuck] = React.useState(false);
  const refBox = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio < 1) {
          setIsStuck(true);
        } else {
          setIsStuck(false);
        }
      },
      { threshold: 1 }
    );

    const current = refBox.current;

    current && observer.observe(current);

    return () => {
      current && observer.unobserve(current);
    };
  }, [setIsStuck]);

  return (
    <Box
      as="header"
      backgroundColor={isStuck && !isHomepage ? 'white' : 'transparent'}
      py={{ base: '3', md: '4' }}
      px={{ base: 7, md: '6' }}
      position={variant === 'base' ? 'sticky' : 'absolute'}
      top="-1px"
      left="0"
      right="0"
      zIndex="sticky"
      ref={refBox}
    >
      <Container
        display="flex"
        justifyContent="space-between"
        maxW="container.lg"
        padding="0"
      >
        {/* TODO: add ticket to migrate it later  */}
        <NextLink legacyBehavior href={homepageLink} passHref>
          <Link>
            <RepublicCapitalLogo
              w={{ base: '48px', md: '72px' }}
              h={{ base: '48px', md: '72px' }}
              fill={isHomepage ? 'white' : 'black'}
            />
          </Link>
        </NextLink>
        <Flex align="center">
          {breakpoint > E_BREAKPOINT.sm && (
            // TODO: add ticket to migrate it later
            <NextLink
              legacyBehavior
              href={headerLinks.portfolio.href ?? ''}
              passHref
            >
              <Button as="a" variant="static-link" size="lg">
                {headerLinks.portfolio.label}
              </Button>
            </NextLink>
          )}
          {breakpoint > E_BREAKPOINT.sm && (
            // TODO: add ticket to migrate it later
            <NextLink
              legacyBehavior
              href={headerLinks.blog.href ?? ''}
              passHref
            >
              <Button as="a" variant="static-link" size="lg">
                {headerLinks.blog.label}
              </Button>
            </NextLink>
          )}
          {breakpoint > E_BREAKPOINT.sm && (
            <Button
              ref={Subscribe.onClickHandlerRef}
              variant="static-header-outline"
              size="lg"
              ml="24px"
              style={{ textTransform: 'uppercase' }}
            >
              {headerLinks.contactUs.label}
            </Button>
          )}
          {breakpoint < E_BREAKPOINT.md && <HamburgerMenu />}
        </Flex>
      </Container>
    </Box>
  );
};
