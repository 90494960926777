import React from 'react';

import { Button, ButtonProps } from '@chakra-ui/react';
import NextLink from 'next/link';

interface ILinkButtonProps extends ButtonProps {
  href: string;
  label: string;
}

// TODO: add ticket to migrate it later
export const LinkButton = React.forwardRef<
  HTMLButtonElement | null,
  ILinkButtonProps
>(({ href, label, ...props }, ref) => (
  <NextLink legacyBehavior href={href} passHref>
    <Button
      ref={ref}
      as="a"
      variant="ghost"
      justifyContent="start"
      size="lg"
      fontSize="38px"
      fontWeight="regular"
      borderRadius={0}
      pt={12}
      pb={10}
      _hover={{ bgColor: 'whiteAlpha.700' }}
      {...props}
    >
      {label}
    </Button>
  </NextLink>
));
