import React from 'react';

import Script from 'next/script';

export function Subscribe() {
  return (
    <>
      {/* https://www.mailerlite.com/video-tutorials/embedded-forms-overview*/}
      {/* We are using external service for user subscription. Business wants pop-up window to be shown*/}
      <Script defer>
        {`
          (function (w, d, e, u, f, l, n) {
              w[f] = w[f] || function () {
                  (w[f].q = w[f].q || [])
                  .push(arguments);
              }, l = d.createElement(e), l.async = 1, l.src = u,
              n = d.getElementsByTagName(e)[0], n.parentNode.insertBefore(l, n);
          })(window, document,'script','https://assets.mailerlite.com/js/universal.js','ml');

           ml('account', '180980');
        `}
      </Script>
    </>
  );
}

// most clean way to set attribute on element for mailerLiter script
Subscribe.onClickHandlerRef = (el: HTMLElement | null) => {
  try {
    el?.classList.add('ml-onclick-form');
    el?.setAttribute('onclick', `ml('show', '7jFoRt', true)`);
  } catch (e) {
    /* do nothing */
  }
};
