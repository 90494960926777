import { AccordionButton, AccordionItem, Flex, Text } from '@chakra-ui/react';

export interface IHompageAccordionItemProps {
  title: string;
  link: string;
}

export function HomepageAccordionItem({
  title,
  link,
}: IHompageAccordionItemProps) {
  return (
    <AccordionItem>
      {() => (
        <AccordionButton as={Flex}>
          <Text mr="auto">
            <a href={link} target="_blank" rel="noreferrer">
              {title}
            </a>
          </Text>
        </AccordionButton>
      )}
    </AccordionItem>
  );
}
