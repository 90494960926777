import React from 'react';

import { Box, Container, Flex } from '@chakra-ui/react';

import { Footer } from '../footer/footer';
import { Header } from '../header/header';
import { Subscribe } from './subscribe';

interface ILayoutProps {
  children?: React.ReactNode;
  padTop?: boolean;
  gutterBottom?: boolean;
  variant?: 'base' | 'homepage';
}

export const Layout = ({
  children,
  padTop,
  gutterBottom,
  variant = 'base',
}: ILayoutProps) => {
  const isHomepage = variant === 'homepage';

  return (
    <Flex
      minH="100vh"
      overflowX={['hidden', 'initial']}
      direction="column"
      justify="center"
      background="white"
    >
      <Header variant={variant} />
      {isHomepage ? (
        <Box>{children}</Box>
      ) : (
        <Container
          as="main"
          maxW={'container.lg'}
          flexGrow={1}
          p={{ base: '0 28px', xl: 0 }}
          mt={padTop ? 6 : 0}
          mb={gutterBottom ? 12 : 0}
        >
          {children}
        </Container>
      )}
      <Footer />
      <Subscribe />
    </Flex>
  );
};
