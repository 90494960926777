export const List = {
  variants: {
    static: {
      container: {
        pl: 10,
      },
      item: {
        mt: 5,
      },
    },
  },
};
