import {
  Accordion,
  Box,
  BoxProps,
  Container,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';

import { HomepageAccordionItem } from './accordion/homepage-accordion-item';

export function Playbook(props: BoxProps) {
  return (
    <Box
      bg="black"
      color="white"
      pt={['64px', '96px']}
      pb={['64px', '88px']}
      {...props}
    >
      <Container maxW="container.lg" px={[7, null, null, null, 'unset']}>
        <Flex
          flexDir="column"
          alignItems={{
            md: 'center',
            lg: 'unset',
          }}
        >
          <Heading variant="homepage">Research</Heading>
          <Flex
            mt={[8, 12]}
            flexDir={{
              base: 'column',
              lg: 'row',
            }}
          >
            <Box mr="34px" fontSize={['16px', '40px']} maxW="488px">
              <Text lineHeight={['19.5px', '48px']}>
                Republic Capital develops theses and publishes research across
                its industries of focus
              </Text>
            </Box>

            <Accordion
              ml={[0, 0, 0, 'auto', '34px']}
              mt={[8, null, null, 0]}
              variant="primary"
              size={['sm', 'md']}
              w="584px"
              maxW="full"
              borderBottom="0.5px solid white"
              allowToggle
            >
              <HomepageAccordionItem
                title="The Shifting World Order"
                link="https://republiccapital.docsend.com/view/s/hbcrraxebttqd7dr"
              />
              <HomepageAccordionItem
                title="The Space Report"
                link="https://republiccapital.docsend.com/view/s/93iiga9x3tni8h2e"
              />
              <HomepageAccordionItem
                title="The State of Nuclear"
                link="https://republiccapital.docsend.com/view/s/pmgk2b8x295sjfgr"
              />
              <HomepageAccordionItem
                title="Additional Research"
                link="https://republiccapital.co/insights"
              />
            </Accordion>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}
