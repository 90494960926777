import React from 'react';

import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';

import { WordRotator } from '../word-rotator/word-rotator';
import background from './images/background.png';
import mask from './images/mask.svg';
import poster from './images/poster.jpeg';

export interface IHomepageHeaderProps {
  video: string;
}

export function HomepageHeader({ video }: IHomepageHeaderProps) {
  const [isBase] = useMediaQuery('(max-width: 992px)', {
    fallback: true,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      bg="white"
      h={{
        base: '100vh',
        lg: 'unset',
      }}
      maxH={['800px', 'unset']}
      backgroundImage={{
        base: `url('${background}')`,
        lg: 'initial',
      }}
      backgroundSize={'cover'}
      px={[7, 0]}
    >
      <Box
        pos="relative"
        maxW="55%"
        width="full"
        display={{ base: 'none', lg: 'block' }}
        overflow="hidden"
      >
        {!isBase && (
          <>
            <video
              width="1500"
              preload="none"
              style={{
                objectFit: 'cover',
                maxWidth: '70vw',
                transform: 'translateX(-230px)',
              }}
              poster={poster}
              src={video}
              autoPlay
              loop
              muted
            />

            <Image
              src={mask}
              h="100%"
              objectFit="cover"
              maxW="none"
              pos="absolute"
              top={0}
              left="55%"
            />
          </>
        )}
      </Box>
      <Flex
        h="full"
        flexDir="column"
        justifyContent="center"
        my="auto"
        width={{
          base: 'full',
          lg: 'unset',
        }}
        alignItems={{
          base: 'center',
          lg: 'flex-start',
        }}
      >
        <Box
          fontSize={{ base: '52px', lg: '50px', xl: '74px' }}
          lineHeight={{ base: '57px', lg: '67px', xl: '78px' }}
        >
          <Text>We invest in&nbsp;a </Text>
          <Flex flexDir={['column', 'row']}>
            <WordRotator />
          </Flex>
          <Text> future</Text>
        </Box>

        <Text
          mt={{
            base: '48px',
            lg: '25px',
            xl: '44px',
          }}
          fontSize={['16px', '27px']}
          lineHeight={['27px', '37px']}
        >
          We invest in a non-obvious future across <br /> frontier technologies.
        </Text>

        {isBase && (
          <Flex justifyContent="center">
            <Button
              onClick={onOpen}
              variant="static-header-outline"
              size="lg"
              fontWeight={700}
              mt="60px"
            >
              Play Video
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size="full">
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalBody as={Flex} alignItems="center">
                  <video
                    poster={poster}
                    src={video}
                    autoPlay
                    loop
                    data-testid="modal-video"
                  ></video>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
