import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Button: ComponentStyleConfig = {
  baseStyle: {
    _focus: {
      boxShadow: 'none',
    },
  },
  variants: {
    'static-outline': {
      fontSize: 'xs',
      fontWeight: '700',
      border: ' 1px solid black',
      borderColor: 'black',
      borderRadius: 'full',
      textTransform: 'uppercase',
      bg: 'white',
      color: 'black',
      h: '25px',
      lineHeight: 1,
      _hover: {
        bg: 'rgba(0, 0, 0, 0.05)',
      },
    },
    'static-solid': {
      fontSize: 'xs',
      fontWeight: '700',
      border: ' 1px solid black',
      borderRadius: 'full',
      textTransform: 'uppercase',
      bg: 'black',
      color: 'white',
      h: '25px',
      lineHeight: 1,
      _hover: {
        color: 'black',
        bg: 'rgba(0, 0, 0, 0.05)',
      },
    },
    'static-link': {
      fontSize: 'md',
      fontWeight: '400',
      border: 'none',
      color: 'black',
      textDecoration: 'none',
      _hover: {
        textDecoration: 'none',
      },
    },
    'static-header-outline': {
      fontSize: 'sm',
      fontWeight: '700',
      textTransform: 'uppercase',
      border: ' 1px solid black',
      borderRadius: 'full',
      letterSpacing: '0.19em',
      h: '40px',
      _hover: {
        bg: 'black',
        color: 'white',
      },
    },
    link: {
      fontSize: 'md',
      fontWeight: '300',
      border: 'none',
      color: 'brand.primary.500',
      textDecoration: 'none',
      _hover: {
        color: 'brand.primary.400',
        textDecoration: 'underline',
      },
    },
  },
};
