import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
} from '@chakra-ui/react';
import NextLink from 'next/link';

import { IPortfolioItem } from '../../models/portfolio-item';
import { PortfolioItem } from '../portfolio-item/portfolio-item';

export interface IPortfolioProps {
  portfolio: IPortfolioItem[];
}

export function Portfolio({ portfolio }: IPortfolioProps) {
  return (
    <Box py={{ base: '55px', md: '96px' }}>
      <Container
        maxW="container.lg"
        px={[7, null, null, null, 'unset']}
        overflow="hidden"
      >
        <Heading mb={12} variant="homepage">
          Portfolio
        </Heading>

        <Grid
          templateColumns={[
            'repeat(6, 259px)',
            null,
            'repeat(auto-fit, minmax(270px, 1fr))',
            'repeat(auto-fit, minmax(368px, 1fr))',
            'repeat(auto-fit, minmax(368px, 1fr))',
          ]}
          gap={[6, 0]}
          templateRows={['259px', null, 'repeat(2, 270px)', 'repeat(2, 368px)']}
          overflow={['scroll', 'initial']}
          scrollSnapType="x mandatory"
        >
          {portfolio.map((item, i) => (
            <GridItem
              key={i}
              scrollSnapAlign="center"
              data-testid="portfolio-item"
            >
              <PortfolioItem item={item} />
            </GridItem>
          ))}
        </Grid>
        <Flex justifyContent="center" fontSize="10px">
          <Box mt={2}>
            The following portfolio companies constitute “value add” strategic
            investments placed by Republic Capital Adviser LLC. In each case,
            Republic Capital or its affiliates holds a board seat, has
            introduced a keystone clients or investors or has provided material
            support outside of its investment of capital to the applicable
            portfolio company.
          </Box>
        </Flex>
        {/* TODO: add ticket to migrate it later  */}
        <Flex mt={8} justifyContent="center">
          <NextLink legacyBehavior href="/companies-portfolio" passHref>
            <Button as="a" variant="static-header-outline" size="lg">
              See all
            </Button>
          </NextLink>
        </Flex>
      </Container>
    </Box>
  );
}
