import { cssVar } from '@chakra-ui/react';
import type { ComponentStyleConfig } from '@chakra-ui/theme';
const $arrowBg = cssVar('popper-arrow-bg');

export const Tooltip: ComponentStyleConfig = {
  baseStyle: {
    background: 'white',
    boxShadow: 'lg',
    [$arrowBg.variable]: 'white',
    color: 'brand.gray.700',
  },
};
