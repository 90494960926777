export const Text = {
  sizes: {
    lead: {
      fontSize: 'xl',
      lineHeight: 'xl',
      letterSpacing: 'tight',
    },
    'body-lg': {
      fontSize: 'lg',
      lineHeight: 'lg',
      letterSpacing: 'tight',
    },
    'body-md': {
      fontSize: 'md',
      lineHeight: 'md',
      letterSpacing: 'less-tight',
      fontWeight: 300,
    },
    'body-sm': {
      fontSize: 'sm',
      lineHeight: 'sm',
      letterSpacing: 'less-tight',
    },
    'body-xs': {
      fontSize: 'xs',
      lineHeight: 'sm',
      letterSpacing: 'normal',
    },
  },
};
