import { Box, BoxProps, Container, Flex, Text } from '@chakra-ui/react';

import { contactLinks } from '../../utils/constants';
import { PrimaryLinksSection } from './primary-links-section/primary-links-section';
import { SecondaryLinksSection } from './secondary-links-section/secondary-links-section';

export function Footer(props: BoxProps) {
  return (
    <Box as="footer" bg="black" color="white" {...props}>
      <Container maxW="container.lg" px={[7, 4, 'unset']}>
        <Flex
          h="full"
          alignItems="start"
          flexDirection="column"
          my={{ base: 16, md: 24 }}
        >
          <Flex
            alignItems="start"
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <PrimaryLinksSection
              links={[contactLinks.twitter, contactLinks.linkedin]}
              mb={{ base: 12, md: 0 }}
            />

            <Flex flexDirection="column">
              <Box>
                <Flex mb={6} flexWrap="wrap" gap={{ base: 12, md: 0 }}>
                  <SecondaryLinksSection
                    header="About"
                    links={[
                      contactLinks.contact,
                      contactLinks.privacyPolicy,
                      contactLinks.disclaimer,
                      contactLinks.cookie,
                      contactLinks.terms,
                      contactLinks.blog,
                    ]}
                    mr={{ base: 0, md: 12 }}
                  />

                  <SecondaryLinksSection
                    header="Our family"
                    links={[
                      contactLinks.republic,
                      contactLinks.republicRetail,
                      contactLinks.republicCrypto,
                    ]}
                    mr={{ base: 0, md: 12 }}
                  />

                  <SecondaryLinksSection
                    header="Insights"
                    links={[contactLinks.subscribe]}
                  />
                </Flex>
              </Box>
            </Flex>
          </Flex>
          <Text
            alignSelf="center"
            mt="auto"
            color="whiteAlpha.500"
            fontSize="sm"
          >
            Republic Capital™ 2023, All rights reserved
          </Text>
        </Flex>
      </Container>
    </Box>
  );
}
