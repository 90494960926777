import React from 'react';

import styles from './word-rotator.module.scss';

export function WordRotator() {
  return (
    <div className={styles.morphing}>
      <div className={styles.content}></div>
      <div className={styles.word}>different</div>
      <div className={styles.word}>resilient</div>
      <div className={styles.word}>contrarian</div>
      <div className={styles.word}>sustainable</div>
    </div>
  );
}
