import { Subscribe } from '../components/layout/subscribe';

export interface IContactLink {
  href?: string;
  label: string;
  target?: string;
  refFunc?: (el: HTMLElement | null) => void;
}

export const contactLinks: Record<string, IContactLink> = {
  twitter: { href: 'https://twitter.com/_rcapital_', label: 'Twitter' },
  linkedin: {
    href: 'https://www.linkedin.com/company/republic-capital',
    label: 'LinkedIn',
  },
  contact: {
    label: 'Contact',
    refFunc: Subscribe.onClickHandlerRef,
  },
  email: { href: 'mailto:ir@republiccapital.co', label: 'Email' },
  republicCrypto: {
    href: 'https://republiccrypto.com/',
    label: 'Republic Crypto',
    target: '_blank',
  },
  republic: {
    href: 'https://group.republic.com/',
    label: 'Republic',
    target: '_blank',
  },
  republicRetail: {
    href: 'https://republic.com/  ',
    label: 'Republic Retail',
    target: '_blank',
  },
  subscribe: {
    href: 'https://dashboard.mailerlite.com/forms/180980/69594835142575855/share',
    label: 'Subscribe',
    target: '_blank',
  },
  privacyPolicy: {
    href: '/privacy',
    label: 'Privacy Policy',
  },
  disclaimer: {
    href: '/disclaimer',
    label: 'Disclaimer',
  },
  cookie: {
    href: '/cookie',
    label: 'Cookies Disclosure',
  },
  terms: {
    href: '/terms-of-service',
    label: 'Terms of Service',
  },
  blog: {
    href: '/insights',
    label: 'Insights',
  },
};

export const headerLinks: { [key: string]: IContactLink } = {
  portfolio: { label: 'Portfolio', href: '/companies-portfolio' },
  blog: {
    label: 'Insights',
    href: '/insights',
  },
  contactUs: {
    label: 'Contact Us',
    refFunc: Subscribe.onClickHandlerRef,
  },
};

export const homepageLink = '/';
