import { Box, Button, Container, Heading, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';

export interface IPageErrorMessageProps {
  title?: string;
  subTitle?: string;
  hideReload?: boolean;
  compact?: boolean;
  errorCodeMsg?: string;
  userMessage?: string;
  videoUrl?: string;
}

// TODO: https://linear.app/republic/issue/RCE-1789/[pr-task]-rewrite-pageerrormessage-component-with-the-usage-of-chakra
// [PR-Task]: Rewrite PageErrorMessage component with the usage of Chakra-UI
export const PageErrorMessage = ({
  title = 'Oops! We made a mistake.',
  userMessage = "Apologies for the inconvenience. We've been notified of this error and are working to fix it.",
  subTitle,
  hideReload = false,
  compact,
}: IPageErrorMessageProps) => {
  const router = useRouter();

  return (
    <Container maxW={compact ? '634px' : '1170px'}>
      <Box mt={16} textAlign="center">
        <Heading size="h2" my={4}>
          {title}
        </Heading>
        {!!subTitle && (
          <Text size="body-md" color="gray70" my={4}>
            {subTitle}
          </Text>
        )}
        <Text size="body-md">{userMessage}</Text>
        {!hideReload && (
          <Text size="body-md">
            Please, try to{' '}
            <Button variant="link" onClick={() => router.reload()}>
              reload
            </Button>{' '}
            and repeat the action.
          </Text>
        )}
      </Box>
    </Container>
  );
};
