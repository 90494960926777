import { useEffect, useRef, useState } from 'react';

import { Box, Image, LinkOverlay, useBreakpointValue } from '@chakra-ui/react';
import debug from 'debug';

import { IPortfolioItem } from '../../models/portfolio-item';

const log = debug('republic-capital-feature');

export interface IPortfolioItemProps {
  item: IPortfolioItem;
}

export function PortfolioItem({
  item: { image, video, logo, link },
}: IPortfolioItemProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean | null>(null);
  const isBase = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    'base'
  );

  const boxRef = useRef(null);

  useEffect(() => {
    if (!isBase) {
      return;
    }

    const map = new Map<Element, number>();

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = window.setTimeout(() => {
              videoRef.current
                ?.play()
                .then(() => setIsPlaying(true))
                .catch((e) => log('Video Error:', e));
            }, 1000);

            map.set(entry.target, id);
          } else {
            window.clearTimeout(map.get(entry.target));
            videoRef.current?.pause();
            setIsPlaying(false);
          }
        });
      },
      { threshold: 1, root: null, rootMargin: '0px' }
    );

    const current = boxRef.current;

    if (current) {
      observer.observe(current);
    }
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [isBase]);

  return (
    <LinkOverlay
      as="div"
      pos="relative"
      role="group"
      w="full"
      h="full"
      ref={boxRef}
      href={link}
      target="_blank"
    >
      <Image
        w="full"
        h="full"
        bg="blackAlpha.900"
        objectFit="cover"
        src={image}
        display={isPlaying ? 'none' : 'block'}
        alt="image"
        _groupHover={isBase ? undefined : { display: 'none' }}
      />
      <Box
        w="full"
        h="full"
        display={isPlaying ? 'block' : 'none'}
        _groupHover={isBase ? undefined : { display: 'block' }}
      >
        <video
          ref={videoRef}
          style={{ objectFit: 'cover', height: '100%', width: '100%' }}
          src={video}
          aria-label="video of company"
          loop
          autoPlay
          preload="none"
          playsInline
          muted
        />
      </Box>
      <Image
        maxW="275px"
        src={logo}
        alt="logo"
        pos="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
    </LinkOverlay>
  );
}
