import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Input: ComponentStyleConfig = {
  variants: {
    /**
     * Input component will use "outline" styles by default.
     * Styles set here will override anything in { baseStyle } and { sizes }
     */
    outline: {
      field: {
        border: '1px solid',
        borderColor: 'brand.gray.300',
        boxShadow: 'xs',
        borderRadius: '0',
        _disabled: {
          opacity: '1',
          textColor: 'brand.gray.500',
          backgroundColor: 'brand.gray.50',
        },
      },
    },
  },
};
