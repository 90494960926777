import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  _focus: {
    boxShadow: 'none',
  },
};

export const Link = {
  baseStyle,
  variants: {
    static: {
      color: 'blue.500',
      _active: {
        color: 'red.500',
      },
      _visited: {
        color: 'purple',
      },
    },
  },
};
